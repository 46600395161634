<template>
  <div class="text-left">
    <!-- <BaseHeader :title="'Sites'"></BaseHeader> -->

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class=" mt-2">
        <div class="text-right d-flex p-3 card d-flex align-items-center flex-row w-100 justify-content-between" style>
          <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter Sites"
            name="url"
            type="text"
            hide-details
          />
          <v-spacer></v-spacer>
          <button
            class="my-auto btn-add ml-2"
            @click.stop="
              $store.state.dialog = true;
              this.isEditMode = false;
            "
            >Add new site</button
          >
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="sites"
          :search="$store.state.search"
        >
          <template v-slot:[`item.deleted`]="{ item }">
            <v-chip v-if="item.deleted" class="ma-2" color="error" small
              >Inactive</v-chip
            >
            <v-chip v-else class="ma-2" color="primary" text-color="white" small
              >Active</v-chip
            >
          </template>
          <!-- <template v-slot:[`item.setups`]="{ item }">
            <v-btn color="primary"  outlined  elevation="0" @click="openSetups = true ; Setups(item); activeSite == item; openSetups == true" >
              Setups
            </v-btn>
          </template> -->
          <template v-slot:[`item.actions`]="{ item }">
            <button class="btn btn-outline-primary rounded"  @click="editMode(item)" >Edit</button>
          </template>
        </v-data-table>
        <!-- /datatable -->

        <div class=" mx-0   order-table-custom ">
      
      <!-- <div class=" order-listing-header  d-flex align-items-center justify-content-between w-100  p-3 mx-sm-0 mx-1 ">  
        <div class=" text-left  "> Order Id </div>
        <div class=" text-left ">Topic</div>
        <div class=" text-left">Level</div>
        <div class=" text-left"> Due</div>
        <div class=" text-left">Pages</div>
        <div class=" text-left">Charts</div>
        <div class=" text-left"> Slides</div>
        <div class=" text-left">Price</div>
        <div class=" text-left">Action </div>
     </div> -->
   
     <div class="">
     <!-- <div v-for="(order, index) in orders" :key="index + 'ords'" class="order-listing-body row px-3 py-3 mx-sm-0 mx-1 ">  
        <div class="col-1 d-flex align-items-start justify-content-center text-left">
          <div v-if="isEditable">
            <input
              v-if="order.id"
              class=" ml-2"
              v-model="bulkActionIds"
              name="bulkActionIds"
              :id="`bulkActionIds${order.id}`"
              type="checkbox"
              :value="order.id"
              style="width: 18px !important; height: 18px !important"
            />
            <h3 class="my-0 font-weight-black" v-else>Total</h3>
          </div> 	
          <router-link class="px-2"
            :to="{ name: 'Order', params: { id: order.id } }"
            >{{ order.id || "" }}</router-link>
           
        </div>
        <div class="col-2 text-left">
          <router-link
            :to="{ name: 'Order', params: { id: order.id } }"
            >{{ order.title || "" }}</router-link
          >
          <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ order.subject_area_text || "" }}</div>
        </div>
        <div class="col-1 text-left">{{ order.academic_level_text || "" }}</div>
        <div class="col-2 text-left"
        :class=" order.urgency_text.past_due
            ? 'text-danger'
            : 'text-success'
        "
        >  {{ order.urgency_text.date || "" }} </div>
        <div class="col-1 text-left">{{ order.pages || "" }}</div>
        <div class="col-1 text-left">{{ order.charts || "" }}</div>
        <div class="col-1 text-left"> {{ order.slides || "" }}</div>
        <div class="col-1 text-left">
          <span class="font-weight-bold" v-if="order.id"
          >${{ order.cost || "" }}</span
        >
        <h3 class="my-0 font-weight-black" v-else>
          ${{ order.cost || "" }}
        </h3>
        </div>
        <div class="col-2 text-left  ">
          <div class="btn-group" v-if="order.id">
          <router-link
            class="btn btn-outline-success btn-sm"
            :to="{ name: 'Order', params: { id: order.id } }"
            >View</router-link
          >
          <router-link
            v-if="isEditable"
            class="btn btn-success btn-sm"
            :to="{ name: 'payment', params: { id: order.id } }"
            >Pay</router-link
          >
          <router-link
            v-if="isEditable"
            class="btn btn-outline-success btn-sm"
            :to="{ name: 'EditOrder', params: { id: order.id } }"
            >Edit</router-link
          >
        </div>
        </div>
     </div> -->
    </div>

    </div>
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="sitesForm.busy">
          <v-card-title class="text-h5"
            >{{ isEditMode ? "Edit" : "New" }} Site</v-card-title
          >
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill"
              ref="sitesForm"
              @submit.prevent="createSite"
            >
              <div class>
                <div class="text-left mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Site Name"
                      name="site_name"
                      type="site_name"
                      v-model="sitesForm.name"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="site_name"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Site Url"
                      name="url"
                      type="link"
                      v-model="sitesForm.url"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="url"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Gateway Checkout Url"
                      name="gateway_checkout_url"
                      type="link"
                      v-model="sitesForm.gateway_checkout_url"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="gateway_checkout_url"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Application Url"
                      name="application_url"
                      type="link"
                      v-model="sitesForm.application_url"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="application_url"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Site Email"
                      name="email"
                      type="email"
                      v-model="sitesForm.email"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="email"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Tawk Code"
                      name="tawk_code"
                      type="text"
                      v-model="sitesForm.tawk_code"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="tawk_code"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" class="text-left">
                    <label for="client_id">Client</label>
                    <v-select
                      dense
                      outlined
                      hide-details
                      placeholder="Client: Start typing to search"
                      v-model="sitesForm.client_id"
                      :items="clients"
                      item-text="first_name"
                      item-value="id"
                    ></v-select>
                    <!-- <vue-select
                      label="first_name"
                      required
                      v-model="sitesForm.client_id"
                      placeholder="Client: Start typing to search"
                      :options="clients"
                      :reduce="(client) => client.id"
                    >
                      <template v-slot:option="option"
                        >{{ option.first_name || "" }}
                        {{ option.last_name || "" }}</template
                      >
                    </vue-select> -->
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Captcha Site Key"
                      name="site_key"
                      type="text"
                      v-model="sitesForm.site_key"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="site_key"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Captcha Secret Key"
                      name="secret_key"
                      type="text"
                      v-model="sitesForm.secret_key"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="secret_key"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-checkbox
                      class="mt-0"
                      v-model="is_admin_system"
                      :label="`Is Admin System`"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="6" v-if="!is_admin_system">
                    <v-select
                      dense
                      outlined
                      label="Select admin system"
                      :items="adminSystems"
                      item-text="application_url"
                      item-value="admin_system"
                      name="admin_system"
                      v-model="sitesForm.admin_system"
                      hide-details
                    ></v-select>
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="sitesForm"
                            class="v-messages theme--light error--text"
                            field="admin_system"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" class="text-left">
                    <v-btn
                      :disabled="sitesForm.busy"
                      type="submit"
                      color="primary"
                      >{{ isEditMode ? "Update" : "Create" }} Site</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->


      <!-- setups modal -->
      <!-- <v-dialog
        v-model="openSetups"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card >
          <v-card-title class="text-h5 p-4 border-bottom "
            > {{activeSite.name}} Setups <br/>

             </v-card-title
          >
          <v-card-text>

            <div class="">
            <div class="row p-sm-3 p-2 d-flex align-items-center  justify-content-center ">

              <v-btn
                v-for="(item, index) in setupsMenu" :key="index + 'setups'" 
                class="col-5 m-2 d-flex align-items-center rounded-100  justify-content-start"
                tile
                rounded
                outlined
                color="primary"
                @click="redirectToSiteAttributes(item.routeName);"
              >
                <i :class="item.icon"></i>

                {{item.text}}
              </v-btn>


            </div>
          </div>
          </v-card-text>
        </v-card>
      </v-dialog> -->
      <!-- setups modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {setupsMenu
} from "../../components/sidebar.model";
export default {
  name: "Sites",
  computed: {
    ...mapState("sites", [
      "sitesForm",
      "loading",
      "sites",
      "headers",
      "adminSystems",
    ]),
    ...mapState("clients", ["clients"]),
  },
  data() {
    return {
      countries: [],
      activeItem: {},
      activeSite:{},
      openSetups: false,
      isEditMode: false,
      routeName:'',
      setupsMenu: setupsMenu,

      is_admin_system: null
    };
  },

  methods: {
    ...mapActions("sites", ["getSites", "getGetAdminSystems"]),
    ...mapActions("clients", ["getClients"]),
    editMode(item) {
      console.log("this.sitesForm", this.sitesForm);
      this.activeItem = item;
      Object.assign(this.sitesForm, { ...item });
      this.is_admin_system =
        this.sitesForm.is_admin_system == "-1" ? false : true;
      this.sitesForm.site_id = item.id;
      this.isEditMode = true;
      this.$store.state.dialog = true;
    },

    Setups(item) {
      this.activeSite = item;
      this.openSetups = true
      console.log("this.activeSite", this.activeSite);
      console.log("this.openSetups", this.openSetups);


      // Object.assign(this.sitesForm, { ...item });
      // this.is_admin_system =
      //   this.sitesForm.is_admin_system == "-1" ? false : true;
      // this.sitesForm.site_id = item.id;
      // this.isEditMode = true;
      // this.$store.state.dialog = true;
    },
     redirectToSiteAttributes(attribute) {
        // router.push({ name: attribute, params: { siteId: this.siteId } })
        this.$store.state.site_id = this.activeSite && this.activeSite.id;
        console.log("this is the site id===>", this.$store.state.site_id)

        window.open(
        `/home/${attribute}/${this.activeSite && this.activeSite.id}`,
        '_blank' // <- This is what makes it open in a new window.
        );
    },
    createSite() {
      if (this.is_admin_system) {
        this.sitesForm.is_admin_system = "1";
        this.sitesForm.admin_system = "0";
      } else {
        this.sitesForm.is_admin_system = "-1";
      }

      console.log("this.sitesForm", this.sitesForm);
      // return 
      // save new sites as without admin system always
      // this.sitesForm.is_admin_system = "0";
      // this.sitesForm.client_id = "6";
      // this.sitesForm.admin_system = "160";
      this.isEditMode
        ? this.sitesForm
            .put(`${this.$baseUrl}/Sites/Site`)
            .then(() => {
              this.getSites();
              this.getGetAdminSystems();
              this.$store.state.dialog = false;
             

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: "Site updated successfully.",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this.isEditMode = false;
              this.sitesForm.reset();
            })
            .catch((err) => {
              console.log(err);
             

              this.$swal({
              icon: 'error',
              title: 'Error',
              text:  err.response.data
                  ? err.response.data.Message
                  : "Error while updating site",
                style: "danger",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            })
        : this.sitesForm
            .post(`${this.$baseUrl}/Sites/Site`)
            .then(() => {
              this.getSites();
              this.$store.state.dialog = false;
              

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: "Site created successfully.",
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this.isEditMode = false;
              this.sitesForm.reset();
            })
            .catch((err) => {
              console.log(err);
             

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data
                  ? err.response.data.Message
                  : "Error while creating site",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            });
    },
  },
  async mounted() {
    await this.getSites();
    await this.getClients();
    await this.getGetAdminSystems();
  },
};
</script>